* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.home {
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Header */

/* Nav */

/* Home */
.home_container {
  display: flex;
  justify-content: center;
}
.home_style {
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* Table */

.table {
  /* width: 700px; */
}

.th_ratings {
  width: 137px;
}
.td_ratings {
  width: 137px;
  min-width: 137px;
}

.restForm {
  display: flex;
  align-items: center; /* flex-direction: row; */
  gap: 1rem;
  min-width: 485px;
}

.priceSelect {
  height: 37px;
  border-radius: 6px;
  width: 100%;
  border: 1px solid rgb(203, 201, 201);
}

.formBtn {
  height: 3rem;
  width: 4rem;
  border-radius: 5px;
}

.td_buttons {
  width: 90px;
}

/* Title */

.nav-title {
  display: flex;
  justify-content: center;
}

/* Review Page */
.review_page_container {
  display: flex;
  justify-content: center;
  margin: 0 20px;
}

.review_page {
  width: 1100px;
}

/* ***** RestaurantList ***** */

.formNames {
  background-color: #0d6efd !important;
}

.rest_btn {
  cursor: pointer;
}

/* ***** UpdateRestaurantList ***** */

.update_rest_form div {
  margin: 1rem 0;
}

.update_rest_form label {
  margin-bottom: 0.2rem;
}

/* ***** Reviews ***** */

.review_container {
  width: 100%;
  max-height: 450px;
  overflow-y: auto;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
  place-items: center;
}
.card_review {
  background-color: #0d6efd;
  color: #fff;
  border-radius: 10px;

  min-width: 200px;
  height: 200px;
  padding: 10px;

  overflow-y: auto;
}

/* Removes Scrollbar */
.card_review::-webkit-scrollbar {
  display: none;
}

.review_box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.review_text_container {
  /* margin: 20px; */
}

.review_text {
  margin: 0;
}

/* ***** Reviews ***** */

.review_form div:nth-child(1) {
  margin-right: 1rem;
}
.review_form div:nth-child(2) {
  flex: 1;
}

.review_form div {
  margin: 0.7rem 0;
}

.review_form_cont label {
  margin-bottom: 0.4rem;
}

.review_form_cont button {
  margin-top: 1rem;
}

/* Responsive */

@media (max-width: 950px) {
}

@media (max-width: 535px) {
  .font-size td {
    font-size: 0.9rem;
    padding: 5px 3px;
  }

  .cont {
    padding: 0px;
  }
}

@media (max-width: 570px) {
  .table thead th:nth-child(3) {
    display: none;
  }
  .table tbody td:nth-child(3) {
    display: none;
  }
  .review_page {
    width: 100%;
    min-width: 0;
  }
}

@media (max-width: 415px) {
  .table tbody td:nth-child(n + 2):nth-child(-n + 3) {
    display: none;
  }
  .table thead th:nth-child(n + 2):nth-child(-n + 3) {
    display: none;
  }

  .restForm {
    min-width: 340px;
  }

  .card_review {
    min-width: 180px;
    height: 180px;
    padding: 5px;
  }

  .review_container {
    max-height: 390px;
    overflow-y: auto;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  }

  .td_ratings {
    width: 115px;
    min-width: 115px;
  }

  .review_page_container {
    margin: 0;
  }
}

@media (max-width: 361px) {
  .table tbody td:nth-child(n + 2):nth-child(-n + 3) {
    display: none;
  }
  .table thead th:nth-child(n + 2):nth-child(-n + 3) {
    display: none;
  }

  .restForm {
    min-width: 340px;
  }

  .card_review {
    min-width: 165px;
    height: 165px;
    padding: 3px;
  }

  .review_container {
    max-height: 390px;
    overflow-y: auto;
    grid-template-columns: repeat(auto-fill, minmax(165px, 1fr));
  }

  .td_ratings {
    width: 80px;
    min-width: 80px;
  }

  .review_page_container {
    margin: 0;
  }
}
